//Menu latéral gauche 
.sidebar {
    color: rgb(202, 202, 202);
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-color: #303030;
    padding: 20px;
    box-sizing: border-box;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar li {
    margin-bottom: 10px;
    cursor: pointer;
}

body {
    margin-left: 210px;
    margin-top: 50px;
}

.menu-item {
    display: block;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: rgb(202, 202, 202);
    transition: background-color 0.2s ease-in-out;

    &:hover {
        color: #e0ae09;

        a {
            color: #e0ae09;
        }
    }

    a {
        color: rgb(202, 202, 202);
        //enlever le soulignement
        text-decoration: none;
    }

    .submenu-item {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        color: rgb(202, 202, 202);
        transition: background-color 0.2s ease-in-out;

        a {
            color: rgb(202, 202, 202);
        }
    }

    .submenu-item:hover {
        color: #e0ae09;

        a {
            color: #e0ae09;
        }
    }
}

.menu-item.active {
    color: #e0ae09;
}

.icone-menu {
    // margin-right: 10px;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    fill: rgb(202, 202, 202);
}

.submenu {
    padding-left: 30px;
    padding-top: 10px;
}

.menu-item.active .menu-submenu {
    display: flex;
}