// /* The Modal (background) */
// .modal {
//     display: none;
//     /* Hidden by default */
//     position: fixed;
//     /* Stay in place */
//     z-index: 1;
//     /* Sit on top */
//     padding-top: 200px;
//     /* Location of the box */
//     left: 0;
//     top: 0;
//     width: 100%;
//     /* Full width */
//     height: 100%;
//     /* Full height */
//     overflow: auto;
//     /* Enable scroll if needed */
//     background-color: rgb(0, 0, 0);
//     /* Fallback color */
//     background-color: rgba(0, 0, 0, 0.4);
//     /* Black w/ opacity */
// }

// /* Modal Content */
// .modal-content {
//     background-color: #fefefe;
//     margin: auto;
//     padding: 20px;
//     border: 1px solid #888;
//     width: 250px;
//     color: black;
// }

// /* The Close Button */
// .close {
//     color: #aaaaaa;
//     float: right;
//     font-size: 28px;
//     font-weight: bold;
// }

// .close:hover,
// .close:focus {
//     color: #000;
//     text-decoration: none;
//     cursor: pointer;
// }

// .datePicker {
//     width: 80%;
//     height: 100%;
//     background-color: #fff;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     padding: 5px;
//     font-size: 14px;
//     color: #555;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
//     transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
//     margin-top: 10px;
// }

// .btn {
//     background-color: #dc8b21;
//     color: #fff;
//     border: none;
//     border-radius: 4px;
//     padding: 10px 20px;
//     font-size: 14px;
//     cursor: pointer;
//     transition: 0.2s;
//     margin-top: 10px;
//     margin-bottom: 10px;
//     margin-right: 20px;
//     // box-shadow: 4px 6px 2px rgba(0, 0, 0, 0.06);
// }

// .labelDate {
//     font-size: 14px;
//     color: #000;
//     margin-bottom: 10px;
//     margin-top: 10px;
// }

// .formulairePriseService {
//     // mettre le contenu en dessous du bouton croix pour fermer la modal et reduire a 80% le contenu de la modal
//     width: 80%;
//     margin-top: 20px;

// }
/* Style de la modal */
.modal-pds {
    // display: none;
    /* Masquer la modal par défaut */
    position: fixed;
    /* Positionner la modal sur toute la page */
    z-index: 1;
    /* Positionner la modal en premier plan */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* Activer le défilement si la hauteur de la modal est trop grande */
    background-color: rgba(0, 0, 0, 0.4);
    /* Fond sombre semi-transparent */
}

/* Contenu de la modal */
.modal-content-pds {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* Bouton "close" */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}