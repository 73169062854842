.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    padding-left: 220px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    background-color: white;
    border-bottom: solid 1px #ddd;
}

.fil-ariane {
    font-size: 14px;
    padding-right: 10px;

}

.header-right {
    display: flex;
    align-items: center;
}