@media all and (min-width: 480px) {
    .Login {
        padding: 60px 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }

    .Login label {

        display: flex;
        padding-bottom: 10px;
        padding-top: 10px;

    }

    .Login Button {
        margin-top: 10px;
        width: 100px;
        text-align: center;
    }

    .Login input {}
}