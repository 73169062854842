body {
    margin: 0;
    margin-left: 50px;
    margin-right: 50px;
    padding: 0;
    font-family: Roboto, sans-serif;
}

.menu {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 20px;
    max-width: 900px;
    margin: 20px auto;
    // border-bottom: 1px solid #dc8616;
    border-bottom: solid 1px #ddd;
    padding: 10px 0;

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
    }

    .item {
        padding: 18px 28px;
        cursor: pointer;
        transition: color .3s ease-out;
        text-decoration: none;
        color: #111;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-size: 12px;
        margin: 5px 0;
        //mettre en gras le texte
        font-weight: 600;

        &.active {
            color: #e38408;
        }
    }

    .indicator {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 30px;
    }

    .separateur {
        border-right: solid 1px #ddd;
        padding: 0 6px 0 6px;
    }
}