footer {
    color: #6c757d;
    font-size: 14px;
    text-align: center;
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
}

footer p {
    margin: 0;
}