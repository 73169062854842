/* Style de la modal */
.modal-depense {
    // display: none;
    /* Masquer la modal par défaut */
    position: fixed;
    /* Positionner la modal sur toute la page */
    z-index: 1;
    /* Positionner la modal en premier plan */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* Activer le défilement si la hauteur de la modal est trop grande */
    background-color: rgba(0, 0, 0, 0.4);
    /* Fond sombre semi-transparent */
}

/* Contenu de la modal */
.modal-content-depense {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 30px;
    border: 1px solid #888;
    width: 400px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;

    h2 {
        margin-bottom: 10px;
    }

}

.formulaire-depense>div>label {
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
    margin-top: 5px;
}

//aligner les input de la modal 
.formulaire-depense>div>input {
    width: 100%;
    /* arrondir les bords */
    border-radius: 5px;
    border-width: 1px;
}


//aligner les input de la modal 
.formulaire-depense>div>select {
    width: 100%;
    /* arrondir les bords */
    border-radius: 5px;
    border-width: 1px;
    //quand le select est disabled
    color: #000;
    border-color: #000;

}

.boutons-depense {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}