.tuile {
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: solid 1px #ddd;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 30px;
    margin-bottom: 30px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
    color: black;
    // padding-left: 30px;
}

.ligne {
    display: flex;
    // flex-direction: column;
    //prendre toute la longueur 
    width: 100%;
    //centrer le contenu
    justify-content: center;
    align-items: center;
    //ajout d'espace entre les tuiles
    justify-content: space-between;
    // margin-left: 30px;
    // margin-right: 30px;

}

.titre-tuile {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #474747;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    //mettre en majuscule
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;

}

.valeur-tuile {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.tuile-info {
    border-bottom: #3985e7 solid 5px;
}

.tuile-action .tuile-action-1 {
    border-bottom: #e0ae09 solid 5px;
}

.tuile-action-1 {
    // width: 600px;
}

.tuile-erreur {
    border-bottom: #e62e2e solid 5px;
}

.icone {
    width: 22px;
    height: 22px;
    fill: #000000;

    &:hover {
        transition: 0.2s;
        transform: scale(1.2);
        fill: #e0ae09;
    }

}

.graphe {
    width: 100%;
}

.bouton-action {
    // color: #e0ae09;
    width: fit-content;
}

.contenu-tuile {
    display: grid;
}

.contenu-action {
    column-gap: 10%;

    a {
        text-decoration: underline;
        color: black;
    }

    button {
        margin: 5px;
    }

}




// // * {
// //     box-sizing: border-box;
// //     font-family: 'Ubuntu', sans-serif;
// // }

// ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;
// }


// .container {
//     width: 1000px;
//     margin: 0 auto;

//     &:after,
//     &:before {
//         content: '';
//         display: block;
//         clear: both;
//     }
// }

// .header {

//     &__logo {
//         font-size: 24px;
//     }

//     &--flex {
//         display: flex;
//     }

//     &--vcenter {
//         align-items: center;
//     }

//     &--hbetween {
//         justify-content: space-between;
//     }
// }

// .menu {

//     &__list {
//         display: flex;
//         flex-wrap: wrap;
//     }

//     &__trigger {
//         display: block;
//         background-color: #f1f1f1;
//         margin-right: 5px;
//         margin-bottom: 5px;
//         font-size: 14px;
//         padding: 10px 20px;
//         transition: 0.2s;

//         &:hover {
//             background-color: #ddd;
//         }
//     }
// }

// .box1 {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100px;
//     background-color: #333;
//     color: #000;
// }

// .titre {
//     width: 50%;
//     // display: inline-block;
//     margin: 0 0 15px;
//     position: relative;
//     font-weight: 500;
//     font-size: 20px;
//     color: #000;

//     &:hover {
//         a {
//             display: flex;
//         }
//     }
// }

// .titre-div {
//     display: flex;
// }

// .titre-droit {
//     padding-left: 50px;

// }

// .content {
//     padding-bottom: 20px;

//     &__section {
//         border-bottom: solid 1px #ddd;
//         padding: 20px 0;

//         &:last-child {
//             padding-bottom: 0;
//             border-bottom: 0;
//         }
//     }
// }

// .trigger-wrapper {
//     display: flex;
//     flex-basis: 100%;

//     .menu-trigger {
//         margin-right: 10px;
//         width: 50%;

//         &:last-child {
//             margin-right: 0;
//         }
//     }
// }

// .ribbon {
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 130px;

//     img {
//         max-width: 100%;
//         display: block;
//     }
// }

// .box-holder {
//     display: inline;

//     .box-infos {
//         display: inline-block;
//         position: absolute;
//         margin-left: 20px;
//     }

//     .box-action {
//         width: 50%;
//         display: inline-block;
//         border-right: solid 1px #b2adad;
//         background-color: white;
//         padding: 10px 20px;
//         margin: 0;
//     }

//     .box {
//         border: 2px solid #a5a39f;
//         background-color: #dc8b21;
//         border-radius: 4px;
//         height: 100px;
//         width: 90px;
//         padding: 20px;
//         align-items: center;
//         transition: 0.2s;
//         margin-bottom: 10px;
//         margin-right: 20px;
//         cursor: pointer;
//         display: inline-block;
//         vertical-align: top;
//         box-shadow: 4px 6px 2px rgba(0, 0, 0, 0.06);
//         background-color: #e8ebf0;

//         &:hover {
//             background-color: #dc8b21;
//         }
//     }

//     .box-top {
//         text-align: center;
//         // border-radius: 4px;
//         grid-column: 1;
//         grid-row: 1;

//         img {
//             width: 60px;
//         }
//     }

//     .box-bottom {
//         text-align: center;
//         grid-column: 1;
//         grid-row: 2;

//         h3 {
//             margin: 5px;
//             font-size: 13px;
//             transition: 0.2s;
//             color: #000;
//         }

//     }

//     .icon {
//         height: 90px;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         svg {
//             width: 30px;
//             height: 30px;
//             fill: #fff;
//             transition: 0.2s;
//         }
//     }

//     .box-stat {
//         border: solid #dc8b21;
//         // background-color: #dc8b21;
//         border-radius: 4px;
//         font-size: 12px;

//         // display: inline-block;
//         padding: 20px;
//         justify-content: space-between;
//         align-items: center;
//         transition: 0.2s;
//         margin: 20px;
//         position: relative;
//         color: black;

//     }

//     .box-info-ligne {
//         display: inline-block;
//         background-color: white;
//         // border: solid #969191;

//         border-radius: 4px;
//         margin: 5px;
//     }
// }

// a:hover,
// a:active,
// a:link {
//     text-decoration: none;
// }



// .contextmenu__item {
//     font-weight: 500;
//     display: flex;
//     align-items: center;

//     img {
//         width: 15px;
//         height: 15px;
//         margin-right: 15px;
//     }
// }

// .contextmenu {
//     width: 250px;
// }



// .nested {
//     background-color: #FF2E57;
//     border-radius: 4px;
//     padding: 15px;
//     color: #fff;
//     font-size: 20px;

//     .nested_item {
//         background-color: #e52b4f;
//         padding: 15px;
//         margin-top: 15px;
//         border-radius: 4px;
//     }
// }