.tableau-comptes {
    width: auto;

    th {
        padding-left: 10px;
        padding-right: 10px;
        //ajout de fines bordures
        border: solid 1px rgba(221, 221, 221, 0.5);
    }

    td {
        padding-left: 10px;
        padding-right: 10px;
        border: solid 1px rgba(221, 221, 221, 0.5);
    }

    .identifiant-compte {
        min-width: 150px;
        width: max-content;
    }

    .statut {
        width: max-content;
    }

    .numero {
        width: fit-content;
    }


    .action {
        width: fit-content;
    }


    .info {
        //la taille de la cellule va prendre toute la place restante de la ligne
        width: 90%;
    }

    .etiquette-verte>span {
        color: #fff;
        background-color: #28a745;
        padding: 6px;
        border-radius: 5px;
        //aligner le texte verticalement
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;

    }

    .etiquette-grise>span {
        color: #fff;
        background-color: #6c757d;
        padding: 6px;
        border-radius: 5px;
        //aligner le texte verticalement
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;

    }

    .etiquette-rouge>span {
        color: #fff;
        background-color: #a72828;
        padding: 6px;
        border-radius: 5px;
        //aligner le texte verticalement
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;

    }

}

.titre-page {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    button {
        padding-top: 3px !important;
        padding-bottom: 1 !important;
        //aligner le texte verticalement
        display: flex;
        align-items: center;
        justify-content: center;

    }
}

.titre-page>h1 {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.contenu-principal-comptes {
    background-color: white;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    width: -webkit-fill-available;

}

.ToastAjoutCompte {
    //display au dessus de tout
    z-index: 9999 !important;
    // display:;
    color: white;
}